/* app css stylesheet */
/* * { outline: 1px solid red; background-color: rgba(1, 1, 1, 0.05); } */

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

/* make keyframes that tell the start state and the end state of our object */

.autoScroll {
  animation: floatText 25s infinite linear;
  animation-delay: 1s;
}

.autoScroll:hover {
  animation-play-state: paused;
}

.rc-slider-handle-dragging {
  box-shadow: 0 0 0 2px #ca8a04 !important;
}

@keyframes floatText {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-110%);
  }
}

.info-page-header {
  text-align: center;
  direction: ltr;
  color: #444444;
  font-size: 26px;
  font-weight: 200;
  margin-top: 20px;
  margin-bottom: 20px;
}

.appStoreButton.apple {
  background: url('../img/download-appstore.svg') no-repeat;
}

.appStoreButton.google {
  background: url('../img/download-playstore-alt.svg') no-repeat;
}

.verdict-background-chains {
  background: url('https://i.imgur.com/bW1dz0O.png') no-repeat;
  background-size: 100px 120px;
}

.appStoreButton {
  width: 152px;
  height: 45px;
  display: inline-block;
  text-indent: -9999px;
  text-align: center;
}

.appStoreButtons {
  width: 100%;
  text-align: left;
  z-index: 1000;
}

/* Our tooltip takes up fixed width on larger devices and as much space as necessary on smaller devices. */
@media (min-width: 640px) {
  .tooltip {
    width: 320px !important;
    @apply invisible absolute;
  }
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.onlyMobile {
  display: none !important;
}

@media only screen and (hover: none) {
  .onlyMobile {
    display: block !important;
  }
}

/* For react-responsive-carousel */
.carousel {
  height: 100%;
}
.slider-wrapper {
  height: 100%;
}
.slider-wrapper > .slider {
  height: 100%;
}
.slider-wrapper:not(.axis-vertical) > .slider > .slide {
  height: 100%;
}

input[type='file'] {
  /* Use filepond */
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Primary;
    src: local(HelveticaNeue), local('Arial'), local('Roboto');
  }

  @font-face {
    font-family: CondensedBold;
    src: local(HelveticaNeue-CondensedBold), local('Arial'), local('Roboto');
  }

  .font-primary {
    font-family: Primary;
  }

  .font-secondary {
    font-family: CondensedBold;
  }

  body {
    @apply font-primary;
  }
}

/* For news embedded twitter cards */
.static-tweet {
  margin-left: auto;
  margin-right: auto;
}

.twitter-tweet {
  margin-left: auto;
  margin-right: auto;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}
