.s-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1080px;
  background-color: white;
}

.s-box {
  width: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.s-top {
  display: flex;
  width: 1080px;
  /* padding-left: 29px; */
  justify-content: center;
}

.s-top-c {
  margin: 0px;
}

.s-t-c-logo {
  width: 375px;
}

.s-t-l-img {
  width: 185px;
  height: 185px;
  border-radius: 50%;
  border: 3px solid #8b8b8b;
  box-shadow: 2px 2px 2px 2px white inset, 2px 2px 2px 2px white;
}

.s-t-r-img {
  width: 185px;
  height: 185px;
  border-radius: 50%;
  display: flex;
  border: 3px solid #8b8b8b;
  box-shadow: 4px 4px 4px 4px white inset, 4px 4px 4px 4px white;
}

.s-t-l-txt {
  text-transform: capitalize;
  margin: 20px 0px 5px 0px;
  font-size: 32px;
  padding: 0px;
  width: 350px;
  font-family: sans-serif;
  text-align: center;
  font-weight: 900;
  color: #b7493d;
}

.s-t-r-txt {
  text-transform: capitalize;
  margin: 20px 0px 5px 0px;
  font-size: 32px;
  padding: 0px;
  width: 350px;
  text-align: center;
  font-family: sans-serif;
  /* display: flex;
    flex-wrap: nowrap; */
  /* background-color: blue; */
  font-weight: 900;
  color: #7bb9f6;
}

.s-top-r {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.s-top-l {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

.t-c-botm {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
}

.s-t-c-global {
  color: black;
  font-weight: 700;
  font-size: 24px;
  margin: 0px 0px 0px 0px;
}

.s-t-c-gimg {
  margin: 0px 0px 0px 0px;
  width: 90px;
}

.s-m-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sm-new-cc {
  display: flex;
}

.red-round-bar {
  margin-top: 0px;
  margin-bottom: 8px;
  color: #b7493d;
  height: 41px;
  margin-left: 0px;
  margin-right: 8px;
  background-color: #b7493d;
  border: 0px solid #b7493d;
}

.red-diff-bar {
  margin-top: 0px;
  color: #b7493d;
  height: 24px;
  margin-left: 0px;
  margin-right: 8px;
  background-color: #b7493d;
  border: 0px solid #b7493d;
}

.blue-round-bar {
  margin-top: 0px;
  margin-bottom: 8px;
  color: #7bb9f6;
  height: 41px;
  margin-right: auto;
  margin-left: 8px;
  background-color: #7bb9f6;
  border: 0px solid #7bb9f6;
}

.blue-diff-bar {
  margin-top: 0px;
  color: #7bb9f6;
  height: 24px;
  margin-right: 0px;
  margin-left: 8px;
  float: right;
  background-color: #7bb9f6;
  border: 0px solid #7bb9f6;
}

.blue-diff-txt {
  color: #7bb9f6;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  margin: -11px 0px 0px 4px;
  text-decoration: none;
}

.red-diff-txt {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #b7493d;
  margin-top: -11px;
  font-size: 30px;
  float: right;
  margin-right: 6px;
  text-decoration: none;
}

.red-score-txt {
  border: none;
  border-width: 0;
  box-shadow: none;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #b7493d;
  font-size: 32px;
  margin-top: -8px;
  float: left;
  margin-right: auto;
  margin-bottom: 0px;
  width: 104px;
  text-align: end;
  text-decoration: none;
}

.red-score-point-deduction-strike-txt {
  border: none;
  border-width: 0;
  box-shadow: none;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #b7493d;
  font-size: 32px;
  margin-top: -8px;
  float: left;
  margin-right: 8px;
  margin-bottom: 0px;
  width: 52px;
  text-align: end;
  text-decoration: line-through;
}

.red-score-point-deduction-txt {
  border: none;
  border-width: 0;
  box-shadow: none;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #b7493d;
  font-size: 32px;
  margin-top: -8px;
  float: left;
  margin-right: auto;
  margin-bottom: 0px;
  width: 52px;
  text-align: end;
  text-decoration: none;
}

.blue-score-txt {
  border: none;
  border-width: 0;
  box-shadow: none;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #7bb9f6;
  font-size: 32px;
  margin: 0px;
  margin-top: -8px;
  margin-bottom: 4px;
  margin-right: 35px;
  width: 68px;
  text-align: left;
  text-decoration: none;
}

.blue-score-point-deduction-strike-txt {
  border: none;
  border-width: 0;
  box-shadow: none;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #7bb9f6;
  font-size: 32px;
  margin: 0px;
  margin-top: -8px;
  margin-bottom: 4px;
  width: 52px;
  text-align: left;
  text-decoration: line-through;
}

.blue-score-point-deduction-txt {
  border: none;
  border-width: 0;
  box-shadow: none;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #7bb9f6;
  font-size: 32px;
  margin: 0px;
  margin-top: -8px;
  margin-bottom: 4px;
  width: 52px;
  text-align: left;
  text-decoration: none;
}

.sm-pro {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* width: 500px; */
}

.opacity-my {
  opacity: 0.7;
}

.s-mid {
  margin-top: 0px;
  width: 1080px;
}

.sm-pro-lc {
  display: flex;
  align-items: center;
  width: 545px;
  justify-content: flex-end;
}

.sm-pro-rc {
  display: flex;
  align-items: center;
  width: 545px;
  justify-content: flex-start;
}

.s-m-h {
  font-weight: 700;
  width: 500px;
  text-align: center;
  font-family: sans-serif;
  color: rgb(0, 0, 0);
  font-size: 20px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.sm-diff {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sm-pro2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* background-color: royalblue; */
}

.sm-pro-lc2 {
  display: flex;
  justify-content: flex-end;
  width: 465px;
  height: 28px;
}

.sm-pro-rc2 {
  display: flex;
  justify-content: flex-start;
  width: 545px;
  height: 28px;
  /* background-color: blue; */
}

.red-final-score-txt {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #b7493d;
  margin-top: -8px;
  font-size: 30px;
  float: right;
  margin-right: 6px;
  text-decoration: none;
}

.dif-bb {
  display: flex;
  justify-content: flex-start;
  width: 545px;
  /* background-color: rosybrown; */
}

.h4-dd {
  font-family: sans-serif;
  font-weight: 800;
  font-size: 20px;
  margin-top: 1px;
  letter-spacing: 0rem;
}

.f-c-footer {
  display: flex;
  justify-content: center;
}

.fc-icon1 {
  width: 280px;
  margin: 20px 10px 0px 10px;
}